// Color Variables
$color-primary: #4471B5;
$color-secondary: #A80050;
$color-primary-hover: #2e62b0;
$color-secondary-hover: #930349;
$color-base: #FFFFFF;
$color-text: #868686;
$color-accent: #2E2E2E;
$color-placeholder:#CECECE;
$color-light-bg:#e8e8e8;
$color-border:#DBDBDB;

// Font size variables
$font-size-extra-small: 12px;
$font-size-small: 14px;
$font-size-medium: 16px;
$font-size-large: 18px;
$font-size-x-large: 24px;
$font-size-xx-large: 36px;
$font-size-xxx-large: 56px;

// Font weight variables
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;

// Line height variables
$line-height-extra-small: 1.2;
$line-height-small: 1.5;
$line-height-medium: 1.6;
$line-height-large: 1.8;