/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import '../node_modules/ngx-spinner/animations/ball-elastic-dots.css';
@import '../node_modules/ngx-spinner/animations/ball-clip-rotate.css';

// _theme.scss

@import 'assets/styles/variables.scss';
@import 'ngx-toastr/toastr';

.required:after {
  content:" *";
  color: red;
}

// Themed form styles
.form-control, .form-select {
  border: 1px solid $color-border !important;
  padding: 12px 16px !important;
  color: $color-accent !important;
  transition: border-color 0.3s ease !important;
  border-radius: 8px !important;

  &::placeholder {
    color: $color-placeholder;
  }

  &:focus {
    border-color: $color-primary;
    box-shadow: 0 4px 8px rgba($color-primary, 0.2) !important;
  }

  @media screen and (max-width: 480px) {
    padding: 8px 16px;
  }
}
.form-select-sm {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
  padding-left: 0.5rem !important;
  font-size: 0.875rem !important;
  border-radius: var(--bs-border-radius-sm) !important;
}
.form-floating > .form-select {
  padding-top: 1.625rem !important;
  padding-bottom: 0.625rem !important;
}
.form-check-input {
  &:checked {
    background-color: $color-primary;
    border-color: $color-primary;
  }

  &:focus {
    border-color: $color-primary;
    box-shadow: 0 0 0 0.25rem rgba($color-primary, 0.2);
  }

}

.form-check-label {
  @media screen and (max-width: 480px) {
    font-size: $font-size-extra-small;
  }
}

.form-label {
  color: $color-accent;
  font-weight: $font-weight-medium;
  margin-bottom: 0.25rem;

  a {
    color: $color-secondary;
    text-decoration: none;
  }

  @media screen and (max-width: 480px) {
    font-size: $font-size-extra-small;
  }
}

// text color styles
.text-primary{
  color: $color-primary !important;
}
.text-secondary{
  color: $color-secondary !important;
}

// theme badge styles

.badge{
  border-radius: 30px;
  padding: 6px 16px;
  font-size: $font-size-small;
  text-align: center;
  display: inline-block;
  font-weight:$font-weight-medium ;
  line-height: normal;
  &.green{
      color:#027A48 ;
  background-color:#ECFDF3;
  }
  &.danger{
      background-color:#FFEEEC;
      color:#dc3545;
  }
  &.warning{
      background-color: #FFF4ED;
      color: #B93815;
  }
  &.blue{
      color:$color-primary ;
      background-color:#F3F9FD;
  }
}

// theme table styles
.filter-bar{
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $color-border;
  .search-bar{
    margin-left: auto;
  }

}
.pagination-wrapper{
  border-top: 1px solid rgba($color-border, 0.8);
  padding-top: 16px;

  .pagination{
    justify-content: space-between;
    .no-of-items{
      display: flex;
      .page-link{
        color: rgba($color-accent, 0.5);
        border: none;
        border-radius: 4px;
        padding: 7px 16px;
        font-weight: 500;
        &:focus{
          box-shadow: 0 0 0 0.15rem rgba(68, 113, 181, 0.1);
        }
      }
      .page-item.active{
        .page-link{
          background-color: #F9FAFB;
          color: $color-accent;

        }
      }

    }
    .page-item{
      &.prev .page-link , &.next .page-link{
        border-radius: 8px ;
        border-color: #D0D5DD;
        color: $color-accent;
        font-weight: 500;
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        display: flex;
        align-items: center;

      }
      &.disabled .page-link{
        color: rgba(33, 37, 41, 0.75);
      }
    }
  }
}
.entry-info{
  color: rgba($color-accent, 0.8);
  span{
    font-weight: $font-weight-semi-bold;
  color: rgba($color-accent, 1);

  }
}
.nav-tabs{
  border: 1px solid #D0D5DD;
  border-radius: 8px;
    width: max-content;
    overflow: hidden;
    .nav-item{
      .nav-link{
        padding: 10px 16px;
        font-size: $font-size-small;
        font-weight: $font-weight-semi-bold;
        color: $color-accent;
        border-width: 0 1px 0 1px ;
        border-style: solid;
        border-color:#D0D5DD ;
        border-radius: 0;
        &.active{
          background-color:#F9FAFB ;
          border-color: #D0D5DD ;
          margin: 0;
        }
      }
      &:first-child{
        .nav-link{
        border-left: none;

      }
    }

      &:last-child{
        .nav-link{
          border-right: none ;
        }
      }
    }

}
.page-wrapper{
  margin: 80px 0 0 270px;
  padding: 40px;
  height: calc(100vh - 80px);
  overflow-y:auto ;
}
body:has(nav.sidebar.sidebar-menu-expanded) .page-wrapper{
  margin: 80px 0 0 270px;
}
body:has(nav.sidebar.sidebar-menu-collapsed) .page-wrapper{
  margin: 80px 0 0 70px;
}
.table-theme-primary{
  color: $color-accent;
  thead {
      position: sticky;
      top: 0;
      background: #fff;
      tr{
      border-bottom:1px solid $color-border !important;
          &:hover{
              background-color: transparent;
          }
      }
  }
  tr{
      border-bottom:1px solid $color-border;
      &:hover{
          background: rgba($color-primary , 0.03);
      }
      &:last-child{
        border-bottom: none;
      }
      td,th{
          border: none;
          background-color: transparent;
          font-size: $font-size-small;
      }
      th{
          color: $color-primary;
          font-weight: 600;
      }
  }
  &.height-table-resonsive
  {
      max-height: 300px;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 5px;
        height: 8px;
        background-color: #ffffff;
        border-radius: 8px;
         /* or add it to the track */
      }
  }
}

// Themed button styles
.btn {
  padding: 12px 24px;
  border-radius: 8px ;
  font-weight: $font-weight-semi-bold;

&.btn-sm{
  padding: 4px 12px;
    border-radius: 4px;
    font-size: var(--bs-btn-font-size);
}
  &.btn-primary {
    background-color: $color-primary;
    border-color:$color-primary;

    &:hover {
      background-color: $color-primary-hover;
    }
  }

  &.btn-secondary {
    background-color: $color-secondary;

    &:hover {
      background-color: $color-secondary-hover;
    }
  }

  &.btn-outline {
    background-color: transparent;
    border: 1px solid $color-border;
    color: $color-accent;

    &:hover {
      background-color: rgba($color-border, 0.2);
    }
  }
  &.btn-primary-light {
    background: rgba($color-primary, 0.2);
    border: 0;
    color: $color-primary;

    &:hover {
      background-color: rgba($color-primary, 0.3);
    }
  }

  @media screen and (max-width: 480px) {
    padding: 8px 16px;
    font-size: $font-size-small;
  }
}

// * {
//   font-family: "Inter", sans-serif;
//   font-optical-sizing: auto;
// }


// auth genera css
.switch-secondary {
  display: inline-block;
}

.switch-secondary input {
  display: none;
}

.switch-secondary small {
  display: inline-block;
  width: 38px;
  height: 15px;
  background: $color-text;
  border-radius: 30px;
  position: relative;
  cursor: pointer;
}

.switch-secondary small:before {
  content: "";
  position: absolute;
  width: 19px;
  height: 19px;
  background: $color-base;
  border-radius: 50%;
  top: -2px;
  left: -1px;
  transition: .3s;
  box-shadow: -3px 0 3px rgba(0, 0, 0, 0.25);
}
.switch-secondary input:checked~small {
  background: rgba($color-secondary, 0.5);
  transition: .3s;
}

.switch-secondary input:checked~small:before {
  transform: translate(20px, 0px);
  transition: .3s;
  background: $color-secondary;
  box-shadow: 3px 0 3px rgba(0, 0, 0, 0.25);
}
.modal-dialog{
  max-width: 600px;
  .modal-content {
    background: $color-base;
    border: 1px solid #F1F1F1;
    box-shadow: 0px 4px 15px rgba(100, 100, 100, 0.1);
    border-radius: 5px;
    .modal-title{
      font-size: $font-size-large;
    }
    .modal-body{
      .modal-icon {
          margin-bottom: 16px;
      }
      .title-icon {
          font-weight: $font-weight-semi-bold;
          font-size: $font-size-large;
          line-height: 22px;
          color: $color-accent;
          margin-bottom: 8px;
      }

      .text-icon {
          margin-bottom: 24px;
          font-weight: $font-weight-regular;
          font-size: $font-size-small;
          line-height: 24px;
          color: $color-text;
      }
      &::-webkit-scrollbar {
        width: 10px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: $color-border;
    border-radius: 8px;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #888;
      }
  }

  }
}

.subscription-category {
  .subscription-gategory-item {
    margin-bottom: 12px;

    .gategory-title {
      font-weight: $font-weight-semi-bold;
      font-size: $font-size-medium;
      line-height: 20px;
      color: $color-primary;
      margin-bottom: 8px;
    }

    .subscription-item {
      padding-bottom: 12px;
      margin-bottom: 20px;
      border-bottom: 1px solid $color-border;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 12px;

      .list-title {
        font-weight: $font-weight-medium;
        font-size: $font-size-small;
        line-height: 22px;
        color: $color-accent;
        margin-bottom: 4px;
      }

      .list-text {
        font-size: $font-size-extra-small;
        line-height: 16px;
        color: $color-text;
        margin-bottom: 0;
      }
      //&:last-child{
      //  border-bottom: 0;
      //}
    }
  }
}
/* Login Styles */
.btn-auth{
  min-height: 50px;
  .ngx-spinner-overlay{
    position: relative !important;
    display: block;
    width: auto !important;

    .la-square-jelly-box{
      position:  relative  !important;
      transform: none !important;
    }
  }
}
.wrap-auth {
  height: 100vh;
  overflow: hidden;

  .left-section {
    z-index: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
&::before{
  content: "";
    width: 100%;
    height: 200px;
    background: linear-gradient(
      180deg,
      rgba(85, 150, 216, 0) 0%,
      rgb(68, 113, 181) 100%);
    position: absolute;
    bottom: 0;
}
    .left-section-content {
      margin: 0 40px 48px 40px;
      position: relative;

      .welcome-title {
        font-weight: $font-weight-bold;
        font-size: $font-size-xxx-large;
        line-height: $line-height-small;
        text-align: center;
        text-transform: uppercase;
        color: $color-base;
      }

      .welcome-text {
        font-weight: $font-weight-regular;
        font-size: $font-size-medium;
        line-height: $line-height-small;
        text-align: center;
        letter-spacing: 0.02em;
        color: $color-base;
      }
    }

    .login-bg {
      position: absolute;
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
      object-position: top;
      z-index: -1;
    }

    .logo{
      content: url("./assets/images/logo.png");
      width: 280px;
      margin:42px auto ;
    }

  }

  .right-section {
    min-height: 100vh;
    height:100vh;
    z-index: 0;
    background: $color-base;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow-y: auto;

    .right-section-content {
      width: 100%;
      max-width: 780px;
      margin: auto;
      padding: 40px 70px 0 70px;

      .title {
        font-weight: $font-weight-bold;
        font-size: 36px;
        line-height: 44px;
        margin-bottom: 40px;
        text-align: center;
      }

      .onboarding-card {
        padding: 32px;
        background: $color-base;
        border: 1px solid #F1F1F1;
        box-shadow: 0px 4px 15px rgba(100, 100, 100, 0.1);
        border-radius: 8px;
        margin-bottom: 1.5rem;

        .icon-wrap {
          display: flex;
          justify-content: center;
          margin-bottom: 24px;

          img {
            margin: auto;
            width: 100px;
          }
        }

        .onboarding-card-title {

          font-weight: $font-weight-bold;
          font-size: $font-size-x-large;
          line-height: $line-height-extra-small;
          color: $color-accent;
          text-align: center;
          margin-bottom: 32px;


        }
      }

      .password-wrap {
        position: relative;

        .show-password {
          position: absolute;
          right: 12px;
          color: $color-placeholder;
          font-size: $font-size-small;
          text-decoration: none;
          transition: 0.2s all;
          z-index: 1;
          top: 15px;
          cursor: not-allowed;

          &.active {
            color: $color-primary;
            cursor: pointer;
          }

        }

        .form-control.is-invalid {
          & + .show-password {
            right: 36px;
          }
        }
      }

      .forgot-password {
        color: $color-secondary;
        line-height: $line-height-small;
        text-decoration: none;
        font-weight: $font-weight-medium;
      }

      .forgot-password:hover {
        color: $color-secondary-hover;
      }

      .text {
        font-weight: $font-weight-regular;
        line-height: $line-height-small;
        text-align: center;
        color: #6F6F6F;

        a {
          color: $color-primary;
        }

        .join {
          font-weight: $font-weight-bold;
          line-height: $line-height-small;
          color: $color-secondary;
          text-decoration: none;
        }
      }
    }
  }
  .auth-alert{
    background-color: $color-base;
    display: flex;
    align-items: center;
box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.12);
border-radius: 8px;
.btn-close{
    top: auto;
}

}


.product-card {
  background-color: rgba($color-border, 0.2);
  border: 1px solid $color-border;
  border-radius: 8px;
  padding: 24px;
  margin-top: 56px;

  .product-card-title {
    font-weight: $font-weight-medium;
    font-size: $font-size-medium;
    line-height: 20px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: $color-text;
  }

  .product-actions {
    display: flex;
    justify-content: center;
    gap: 16px;
    margin-top: 16px;
  }
}

}

  .card{
    padding:25px ;
    border-radius: 8px !important;
    border: none !important;
}

.card-title{
    font-size: 18px;
    font-weight: 600;
}

.search-bar{
  position: relative;
  input{
      border-radius: 8px;
      position: relative;
      padding-left: 40px !important;
      background: white;
      font-size: 14px;
      border: 1px solid #D0D5DD;
      &::placeholder{
          color: #667085;
      }
    }
    .search-icon {
      position: absolute;
      left: 15px;
      z-index: 1;
      top: 10px;
    }
    .search-icon img{
    width: 20px;
    }
}
.inner-content-site{
  border-radius: 16px;
  background: rgba($color-light-bg, 0.3);
  padding: 32px;
  margin-bottom: 1.5rem;
  h1{
    font-size: $font-size-xx-large;
    color: $color-secondary;
    font-weight: $font-weight-bold;
  }
  h2{
    font-size: $font-size-x-large;
    color: $color-primary;
    font-weight: $font-weight-semi-bold;
    margin-bottom: 1rem;

  }
  h3{
    font-size: $font-size-large;
    color: $color-primary;
    font-weight: $font-weight-semi-bold;

  }
  p , ol ,li{
    color: $color-text;
    line-height: 26px;
    &::marker{
      color: $color-accent;
    }
  }
  a{
    color: $color-secondary;
  }
}
// accordion custom css
.accordion{
  .accordion-item{
    border-radius: 0;
    border-width:0 0 1px 0 ;
    .accordion-header{
      .accordion-button{
        background-color: transparent;
      box-shadow: none;
      padding-left: 0;
      font-weight: $font-weight-medium;
      &:before {
        flex-shrink: 0;
        width: var(--bs-accordion-btn-icon-width);
        height: var(--bs-accordion-btn-icon-width);
        margin-left: 0;
        content: "";
        background-image: var(--bs-accordion-btn-icon);
        background-repeat: no-repeat;
        background-size: var(--bs-accordion-btn-icon-width);
        transition: var(--bs-accordion-btn-icon-transition);
        margin-right: 8px;

    }
    &::after{
      display: none;
    }
    &.collapsed{
      &:before{
        transform: rotate(-90deg);
      }
    }

      }
    }
  }

}
// date picker custom css 
app-kpi-reporting{
  .md-drppicker.shown{
    left: auto !important;
    right:0 !important;
  }
  app-date-range-selector{
    input{
      background-image: url(assets/images/date-icon.svg);
      background-repeat: no-repeat;
    background-position: right 0.75rem center;
    height: 50px !important;
    cursor: pointer;
    }
  }
  .buttons{
    border-top: 1px solid $color-border;
    clear: both;
    padding-top: 12px;
    .buttons_input{
      display: flex;
      justify-content: end;
      gap: 12px;
      button{
        padding: 4px 12px;
    border-radius: 4px;
    font-size: 0.875rem;
    line-height: 22px;
    display: flex;
    align-items: center;
    &.clear svg{
      margin-top: -10px;
    }
    &:last-child{
      background-color: $color-primary-hover;
    }
      }
    }
  }
  button.active,.md-drppicker td.active{
    background-color: $color-primary !important;
  }
}

/* Responsive Styles */
@media screen and (max-width: 992px) {

  .wrap-auth {
    overflow-y: auto;
    overflow-x: hidden;
    height: auto;

    .right-section {
      min-height: auto;
      height: auto;

      .right-section-content {
        padding: 24px 24px 0 24px;

      }
    }

    .left-section {
      .login-bg {
        &:not(.mobile) {
          display: none;

        }

      }
    }

  }

}
@media screen and (max-width: 580px) {

  .wrap-auth {
    overflow-y: auto;
    overflow-x: hidden;
    .row{
      height: auto !important;
    }

    .right-section {
      min-height: auto;
      height: auto;

      .right-section-content {
        padding: 24px 24px 0 24px;

        .onboarding-card {
          padding: 18px;
          margin-bottom: 1rem;

          .onboarding-card-title {
            font-size: $font-size-medium;
            margin-bottom: 16px;
          }

          .icon-wrap {
            margin-bottom: 16px;

            img {
              width: 68px;
            }
          }
          .show-password{
            top: 14px;
          }
        }

        .title {
          font-size: $font-size-large;
          margin-bottom: 20px;
          line-height: $line-height-extra-small;
        }

        .forgot-password {
          font-size: $font-size-small;
        }

        .text {
          font-size: $font-size-extra-small;
        }
      }
    }

    .left-section {
      height: 164px;
      &::before{
        content: "";
          height: 48px;

      }
      .logo {
        width: 120px;
        margin: 18px 32px 32px;
        filter: drop-shadow(0px 0px 2px #f1f1f1);
      }

      .left-section-content {
        margin: 0 auto 16px;

        .welcome-title {
          font-size: $font-size-x-large;
          line-height: $line-height-small;
          margin-bottom: 0;
        }

        .welcome-text {
          font-size: $font-size-extra-small;
          margin-bottom: 0;
        }
      }

      .login-bg {
        &:not(.mobile) {
          display: none;

        }

      }
    }

  }

}


/* responsive css start  */

/* for ipad  */
@media screen and (max-width:1024px) {
  .dashboard-card{
      margin-bottom: 12px;
      padding: 15px;
  }
  .icon-dashboard-card img{
width: 50px;
  }
  nav.sidebar-menu-expanded {
      width: 200px;
  }
  .header-container{
      padding: 0 24px;
  }
  body:has(nav.sidebar.sidebar-menu-expanded) .page-wrapper {
      margin: 80px 0 0 200px;
  }
  body:has(nav.sidebar.sidebar-menu-collapsed) .page-wrapper {
    margin: 80px 0 0 70px;
}
  .table-theme-primary th:first-child{
      min-width: auto;
      width: auto;
  }
  .table-theme-primary th{
      min-width: 100px;
      width: auto;
  }
  .page-wrapper{
      padding: 30px;
  }
  .logo-parent{
      min-width: auto;
  }
}

/* ipad ended  */

/* for mobile  */
@media screen and (max-width:600px) {
  .dashboard-card{
    margin-bottom: 0px;
}
  .search-bar{
    width:100%;
  }
  .icon-btn{
    .text{
      display:none;
    }
    .icon{
      margin:0 !important;
    }
  }
  .card{
    padding:16px;
  }
  .btn-profile{
      padding-right:0 ;
  }
  .header-container {
      padding: 0 15px;
  }
  nav.sidebar-menu-expanded {
      width: 100%;
      top: 0;
      z-index: 99;
  }
  nav.sidebar.sidebar-menu-collapsed{
      display: none;
  }
 .page-wrapper {
      margin: 60px 0 0 0px !important;
  }
  .page-wrapper {
      padding: 15px !important;
  }
  .height-table-resonsive {
      max-height: 100%;
  }

}

/* for mobile  ended */

/* responsive css ended  */
